<template>
  <div class="column">
    <div class="top_back">
      <img
        class="back"
        src="../../../assets/img/home/home/PES/icon_pes_back.png"
        @click="back"
      />
      <div class="title">PES报告</div>
      <div class="oldEnter" @click="clickOldEnter">旧版报告入口</div>
    </div>
    <div class="row_normal text_center">
      <div class="content">
        <div class="ys_text">园所报告</div>
        <div class="btnStyle">
          <el-button   @click="getYsListPre(2023)" :class="{active: year===2023}">2023年</el-button>
          <el-button   @click="getYsListPre(2024)" :class="{active: year===2024}">2024年</el-button>
        </div>
        <div class="month_list">
          <div v-for="(info, itemIndex) in ysList" :key="itemIndex">
            <div class="normal">
              <div class="pes_normal">
                <div v-if="info.isCreate == 1" class="img_no">
                  <img
                    class="bb"
                    src="../../../assets/img/pes/icon_month_report.png"
                  />
                </div>
                <div v-else class="img_no"></div>
                <div class="grade_name text_center">
                  {{ info.monthInt }}月报告
                </div>
              </div>
              <div
                v-if="info.isCreate == 0"
                class="create_report text_center"
                @click="createReportPre(info)"
              >
                生成报告
              </div>
              <div
                v-else
                class="create_report text_center"
                @click="lookSchoolReport(info)"
              >
                查看
              </div>
            </div>
          </div>
        </div>
        <div class="ys_text mt30">幼儿报告</div>
        <div class="month_list">
          <div v-for="(info, itemIndex) in childrenList" :key="itemIndex">
            <div class="normal">
              <div class="pes_normal">
                <div v-if="info.isCreate == 1" class="img_no">
                  <img
                    class="bb"
                    src="../../../assets/img/pes/icon_month_report.png"
                  />
                </div>
                <div v-else class="img_no"></div>
                <div class="grade_name text_center">
                  {{ info.monthInt }}月报告
                </div>
              </div>
              <div
                v-if="info.isCreate == 0"
                class="create_report text_center"
                @click="createYeReportPre(info)"
              >
                生成报告
              </div>
              <div
                v-else
                class="create_report text_center"
                @click="lookChildrenReport(info)"
              >
                查看
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h100"></div>
    <el-dialog :visible.sync="dialogVis" center @click="isClose">
      <template slot="title">
        <div class="upWT">提示</div>
        <div class="close"></div>
      </template>
      <div class="riseConter text_center">
        <p>本月幼儿数据不完整或个别幼儿未录入，</p>
        <p>是否生成部分数据报告？</p>
        <p>（含全国对比数据）</p>
      </div>
      <div style="text-align: center; margin: 0.2rem auto 0 auto">
        <el-button class="btn active" @click="goInput">去完善</el-button>
        <el-button class="btn" @click="createNow">立即生成</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVis1" center @click="isClose1">
      <template slot="title">
        <div class="upWT">提示</div>
        <div class="close"></div>
      </template>
      <div class="riseConter text_center">
        本月您园所已上传全部幼儿测试数据， 是否立即生成报告？
      </div>
      <div style="text-align: center; margin: 0.2rem auto 0 auto">
        <el-button class="btn active" @click="createNow">是</el-button>
        <el-button class="btn" @click="isClose1">否</el-button>
      </div>
    </el-dialog>
    <div class="pop" v-if="showSelect">
      <div class="pop-content">
        <div class="close" @click="cancelTab">
          <img src="../../../assets/img/product/Icon_Close.png" alt="" />
        </div>
        <div class="top_bg">
          <div class="text">版本选择</div>
        </div>
        <div class="row">
          <div class="flex column">
            <img src="../../../assets/img/product/Pic_PT_New.png" alt="" />
            <div class="news" @click="bindToTzTest">标准体质测试</div>
          </div>
          <div class="flex column">
            <img src="../../../assets/img/product/Pic_PT_Old.png" alt="" />
            <div class="old" @click="bindToYdTest">标准运动测试</div>
          </div>
          <div class="flex column">
            <img src="../../../assets/img/product/Pic_PT_Old.png" alt="" />
            <div class="old" @click="bindToYdTestHigh">高级运动测试</div>
            <div class="kx_text">可选</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "pesNew",
  components: {},
  data() {
    return {
      monthList: [
        { name: "1月", year: "2023", status: 1, error: 0 },
        { name: "2月", year: "2023", status: 0, error: 1 },
        { name: "3月", year: "2023", status: 0, error: 0 },
        { name: "4月", year: "2023", status: 1, error: 0 },
      ],
      tableData: [],
      dialogVis: false,
      dialogVis1: false,
      ysList: [],
      childrenList: [],
      createType: 0,
      monthStr: "",
      showSelect: false,
      year:2024
    };
  },
  mounted() {
    this.getYsList(this.year);
    this.getchildrenList(this.year);
  },
  methods: {
    getYsListPre(year){
      this.year = year;
      this.getYsList(year)
      this.childrenList(year)
    },
    back() {
      this.$router.go(-1);
    },
    bindStu(val) {
      console.log(val);
    },
    clickOldEnter() {
      this.$router.push({
        path: "/PESHome",
      });
    },
    //生成院所报告
    createReportPre(item) {
      this.createType = 0;
      this.monthStr = item.monthStr;
      let data = {
        type: 0,
        schoolId: item.schoolId,
        month: item.monthStr,
      };
      this.api.pes.createNewReportPre(data).then((res) => {
        if (res.data.isComplete == 0) {
          this.dialogVis = true;
        } else {
          this.dialogVis1 = true;
        }
      });
    },
    // 生成幼儿报告
    createYeReportPre(item) {
      this.createType = 1;
      this.monthStr = item.monthStr;
      let data = {
        type: 1,
        schoolId: item.schoolId,
        month: item.monthStr,
      };
      this.api.pes.createNewReportPre(data).then((res) => {
        if (res.data.isComplete == 0) {
          this.dialogVis = true;
        } else {
          this.dialogVis1 = true;
        }
      });
    },
    //生成报告
    createReport() {
      var that = this;
      let data = {
        type: this.createType,
        schoolId: this.$store.state.userInfo.schoolId,
        month: this.monthStr,
      };
      this.api.pes.createNewReport(data).then((res) => {
        if (that.createType == 1) {
          that.$router.push({
            path: "/monthInfo?key=" + encodeURIComponent(res.data.key),
          });
        } else {
          that.$router.push({
            path: "/reportInfo?type=1&key=" + encodeURIComponent(res.data.key),
          });
        }
      });
    },
    isClose() {
      this.dialogVis = false;
    },
    goInput() {
      this.dialogVis = false;
      this.showSelect = true;
    },
    cancelTab() {
      this.showSelect = false;
    },
    bindToTzTest() {
      let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(process.env.VUE_APP_NODE + "?token=" + token + "&testType=2");
    },
    bindToYdTest() {
      let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(process.env.VUE_APP_NODE2 + "?token=" + token);
    },
    bindToYdTestHigh() {
      let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(process.env.VUE_APP_NODE + "?token=" + token + "&testType=1");
    },
    createNow() {
      this.dialogVis = false;
      this.dialogVis1 = false;
      this.createReport();
    },
    isClose1() {
      this.dialogVis1 = false;
    },
    // 查看幼儿报告
    lookChildrenReport(item) {
      this.$router.push({
        path: "/monthInfo?key=" + encodeURIComponent(item.key),
      });
    },
    // 查看园所报告
    lookSchoolReport(item) {
      this.$router.push({
        path: "/reportInfo?type=1&key=" + encodeURIComponent(item.key),
      });
    },
    // 获取园所报告
    getYsList(year) {
      let data = {
        type: 0,
        schoolId: this.$store.state.userInfo.schoolId,
        year: year,
      };
      this.api.pes.testNewRecordList(data).then((res) => {
        this.ysList = res.data;
      });
    },
    // 获取幼儿报告
    getchildrenList(year) {
      let data = {
        type: 1,
        schoolId: this.$store.state.userInfo.schoolId,
        year: year,
      };
      this.api.pes.testNewRecordList(data).then((res) => {
        this.childrenList = res.data;
      });
    },
  },
  computed: {},
};
</script>
    <style lang="scss" scoped>

    .btnStyle {
      margin-bottom: 20px;
      margin-left: 200px;

      button {
        padding: 10px 22px;
        border: none;
        border-radius: 6px;
        color: #ba3037;
        margin-right: 15px;
        font-size: 18px;
        border: 1px solid #ba3037;
      }
      .active {
        background: linear-gradient(232deg, #b93037, #ef525b);
        box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
        color: #fff;
        border: 1px solid #ba3037;
      }
    }
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
}
.top_back {
  position: relative;
  height: 42px;
  background: #333333;
  .back {
    position: absolute;
    top: 13px;
    left: 140px;
    width: 42px;
    height: 15px;
    cursor: pointer;
  }
  .title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .oldEnter {
    position: absolute;
    top: 7px;
    right: 140px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cf5d5d;
    cursor: pointer;
  }
}
.row_normal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.month_lay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #333333;
}
.w1000 {
  width: 1200px;
  height: 40px;
  overflow-x: scroll;
}
.swip-item {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
}
.month_normal {
  width: 70px;
  height: 30px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.text_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.flex {
  flex: 1;
}
.content {
  width: 1200px;
  background: #fff;
  margin-top: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 50px;
}
.pes-select {
  display: flex;
  .select-item {
    width: 130px;
    height: 38px;
    margin-right: 32px;
    /deep/ .el-input__inner {
      font-size: 18px;
    }
  }
}
.pl_input {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b02424;
  margin-left: 20px;
  margin-right: 30px;
}
.h100 {
  height: 100px;
}
.ys_text {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b02424;
  margin-left: 30px;
}
.mt30 {
  margin-top: 30px;
}
.month_list {
  width: 1140px;
  display: grid;
  grid-template-columns: repeat(4, 250px);
  /* justify-content: center; */
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  .normal {
    width: 250px;
    height: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 10px;
    .grade_name {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6b6161;
      position: absolute;
      top: 10px;
      left: 0px;
      width: 100%;
    }
    .img_ha {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e10500;
    }
    .img_no {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f9f9f9;
    }
    .bb {
      position: absolute;
      top: 140px;
      left: 60px;
      width: 130px;
      height: 130px;
    }
  }
  .pes_normal {
    position: relative;
    width: 100%;
    height: 380px;
  }
  .create_report {
    width: 250px;
    height: 30px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-top: 10px;
    background: #e10500;
    border-radius: 10px;
    cursor: pointer;
  }
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #b93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}

/deep/ .el-input__suffix {
  line-height: 0.5rem;
}
.riseConter {
  padding: 30px;

  p {
    color: #333;
    line-height: 45px;
  }
}
.hint {
  color: red !important;
}

.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #cb1b32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #ba3037;
  border-radius: 6px;
  background: #fff;
  color: #ba3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #b93037, #ef525b);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}
.pop {
  width: 100%;
  height: 100%;
  background: #00000060;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .pop-content {
    width: 620px;
    height: 308px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 10px 43px 0px rgba(136, 136, 136, 0.3);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      right: 19px;
      top: 17px;
      cursor: pointer;
      z-index: 1;
      img {
        width: 15px;
        height: 15px;
      }
    }

    .top_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 620px;
      height: 50px;
      background: #b83138;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
      }
    }
    .row {
      display: flex;
      flex-direction: row;

      width: 620px;
      height: 228px;
      position: absolute;
      top: 50px;
      left: 0;
      img {
        width: 152px;
        height: 119px;
      }
    }
    .flex {
      flex: 1;
    }
    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }
    .news {
      width: 160px;
      height: 42px;
      background: linear-gradient(50deg, #b83037, #d24249);
      box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
      border-radius: 21px;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #ffffff;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
    .news:hover {
      background: linear-gradient(50deg, #dd3b44, #f74f58);
      box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
    }
    .old {
      width: 160px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #b83138;
      border-radius: 20px;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #b83138;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
    .kx_text {
      font-size: 18px;
      color: #999999;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
